















































































































































































































































































































































































































.addAndEditData {
  .el-form-item {
    .el-form-item__content {
      .el-input-number {
        width: 100%;
      }
    }
  }

  .setHeight_180 {
    .el-textarea__inner {
      min-height: 180px !important;
    }
  }

  .el-textarea .el-input__count {
    background: transparent;
  }
}
